<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 년도 -->
          <c-datepicker
            :range="true"
            default="today"
            label="작업요청일"
            name="year"
            v-model="searchParam.year"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
          <c-dept type="search" label="작업부서" name="dept2" v-model="searchParam.dept2" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
          <c-select
            type="search"
            :comboItems="comboItems1"
            itemText="codeName"
            itemValue="code"
            name="col4"
            label="작업지시 상태"
            v-model="searchParam.col4"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <q-table
      grid
      title=""
      :data="grid.data"
      :columns="grid.columns"
      hide-header
      hide-bottom
      :rows-per-page-options="[0]"
      virtual-scroll
    >
      <template v-slot:top-right>
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="openPop" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:item="props">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <q-card class="mobileTableCardLayer" @click="linkClick(props.row)">
            <q-card-section horizontal class="bg-grey-2">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">[{{ props.row.col3 }}]{{ props.row.col1 }}</div>
              </q-card-section>
            </q-card-section>
            <q-card-section class="grid-card-etc">
              <div class="text-grid-etc">
                {{ props.row.col4 }} 
              </div>
              <div class="text-grid-etc">
                {{ props.row.col2 }}
              </div>
              <div class="text-grid-etc">
                <q-chip outline square
                  :color="props.row.colcolor"
                  text-color="white">
                  {{props.row.col9}}
                </q-chip>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </template>
    </q-table>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
      <q-card class="mobileTableCardLayer">
        <q-card-section horizontal class="bg-grey-2 text-center">
          <q-card-section class="q-pt-xs">
            <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
          </q-card-section>
        </q-card-section>
      </q-card>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'outernal-manage',
  data() {
    return {
      comboItems1: [
        {
          code: '1',
          codeName: '작업계획중',
        },
        {
          code: '2',
          codeName: '작업계획 결재중',
        },
        {
          code: '3',
          codeName: '작업중',
        },
        {
          code: '4',
          codeName: '작업완료',
        },
      ],
      searchParam: {
        plantCd: null,
        year: '',
        dept1: '',
        dept2: '',
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'col9',
            field: 'col9',
            label: '상태',
            align: 'center',
            type: 'custom',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'col1',
            field: 'col1',
            label: '작업명',
            align: 'left',
            sortable: true,
            type: 'link'
          },
          {
            name: 'col3',
            field: 'col3',
            label: '위치',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '설비',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'col5',
            field: 'col5',
            label: '요청부서',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'col6',
            field: 'col6',
            label: '작업부서',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '요청 종료일',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'col10',
            field: 'col10',
            label: '작업 완료일',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'col7',
            field: 'col7',
            label: '작업유형',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'col8',
            field: 'col8',
            label: '작업지시 발행일',
            align: 'center',
            style: 'width:180px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // this.listUrl = selectConfig.mdm.mam.review.list.url;
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          plantName: '사업장1',
          col1: '지하저장조 배관 변경작업',
          col2: '2022-01-01',
          col3: 'BTBSAG-01',
          col4: '설비 #0001',
          col5: '생산팀 A',
          col6: '제조팀',
          col7: 'CM',
          col8: '2022-01-20 14:23:12',
          col9: '작업계획중',
          stepCd: '1',
          col10: '',
          colcolor: 'blue',
        },
        {
          plantName: '사업장1',
          col1: '설비WEWQA-10 온도 불량',
          col2: '2022-03-12',
          col3: 'BTBSAG-01',
          col4: '설비 #0001',
          col5: '생산팀 A',
          col6: '제조팀',
          col7: 'CM',
          col8: '2022-01-20 14:23:12',
          col9: '작업중',
          stepCd: '2',
          col10: '',
          colcolor: 'orange',
        },
        {
          plantName: '사업장1',
          col1: '설비WEWQA-10 습도 불량',
          col2: '2022-03-27',
          col3: 'BTBSAG-01',
          col4: '설비 #0001',
          col5: '생산팀 A',
          col6: '제조팀',
          col7: 'CM',
          col8: '2022-01-20 14:23:12',
          col9: '작업완료',
          stepCd: '3',
          col10: '2022-03-27',
          colcolor: 'green',
        },
      ]
      // this.$http.url = this.listUrl;
      // this.$http.type = 'GET';
      // this.$http.param = this.searchParam;
      // this.$http.request((_result) => {
      //   this.grid.data = _result.data;
      // },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '작업지시(계획)서 상세';
      this.popupOptions.param = {
        stepCd: row ? row.stepCd : '',
      };
      this.popupOptions.target = () => import(`${'./wo002Detail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
  }
};
</script>
